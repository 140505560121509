import { Entities } from '@inwink/entities/entities';
import { getEntityPredicate } from '@@data/entityfilters';
import { SortAndFilterItems } from '@@pages/teaseritems.props';
import { States } from '@@services/services';
import { userMessageModule } from '@@routes/appmodules';
import { actionQueue } from '@@actionsqueue/index';

function offeringActionQueue() {
    return import("@@event/actionsqueue/actionqueue.favoriteexhibitoroffering");
}

function tracking() {
    return import("@@services/tracking/actions");
}

export function getHomeOfferings(eventdata: States.IEventDataStore): Entities.IExhibitorOffering[] {
    const res = eventdata.exhibitorofferings.data;
    const asArray = Array.prototype.slice.call(res);
    return asArray;
}

export function getOfferingsByIds(
    offeringids: { id: string }[],
    eventdata: States.IEventDataStore
): Entities.IExhibitorOffering[] {
    const res = [];
    if (offeringids && offeringids.length) {
        offeringids.forEach((s) => {
            const offerings = eventdata.exhibitorofferings.data.filter((offering) => s.id === offering.id)[0];
            if (offerings) res.push(offerings);
        });
    }
    return [...res];
}

export function getThemeOfferings(
    eventTheme: Entities.IEventTheme,
    eventdata: States.IEventDataStore
): Entities.IExhibitorOffering[] {
    const res = [];

    if (eventTheme) {
        return eventdata.exhibitorofferings.data.filter((offering) => (offering as any).eventThemes
            .some((e) => e.id === eventTheme.id));
    }

    return res;
}

export function getJourneyOfferings(
    journey: Entities.IJourney,
    eventdata: States.IEventDataStore
): Entities.IExhibitorOffering[] {
    const res = [];

    if (journey) {
        return eventdata.exhibitorofferings.data.filter((offering) => offering.journeys?.some((e) => e.id === journey.id));
    }

    return res;
}

export function getExhibitorOfferings(
    exhibitor: Entities.IExhibitor,
    eventdata: States.IEventDataStore
): Entities.IExhibitorOffering[] {
    const res = [];

    if (exhibitor) {
        return eventdata.exhibitorofferings.data.filter((offering) => offering.exhibitorId === exhibitor.id);
    }

    return res;
}

export function getExhibitorOfferingsOnOfferingDetails(
    exhibitorOffering: Entities.IExhibitorOffering,
    eventdata: States.IEventDataStore
): Entities.IExhibitorOffering[] {
    const res = [];

    if (exhibitorOffering) {
        const exhibitorId = exhibitorOffering.exhibitorId;
        return eventdata.exhibitorofferings.data
            .filter((offering) => (offering.exhibitorId === exhibitorId) && (offering.id !== exhibitorOffering.id));
    }

    return res;
}

export function getRecommendationPredicate(user: States.IAppUserState,
    eventdata: States.IEventDataStore, filter: any, isTeaser?: boolean): (offering: Entities.IExhibitorOffering) => boolean {
    let recommendationPredicate: (offering: Entities.IExhibitorOffering) => boolean = () => true;
    if (user?.checked && (filter?.my_recommendations || isTeaser)) {
        const favoriteEventThemes = user?.currentUser?.data?.favoriteEventThemes?.data;
        const favoriteEventThemeIds: string[] = favoriteEventThemes?.map((et) => et.eventThemeId)
            || [];

        recommendationPredicate = (offering) => {
            return offering.eventThemes && offering.eventThemes
                .some((e) => favoriteEventThemeIds.indexOf(e.id) > -1);
        };
    }

    return recommendationPredicate;
}

function getFavoritePredicate(user: States.IAppUserState, filter: any): (offering: Entities.IExhibitorOffering) => boolean {
    let favoritePredicate: (exhibitor: Entities.IExhibitorOffering) => boolean = () => true;
    if (user?.checked && filter?.bookmarked) {
        const favoriteExhibitorOfferings = user?.currentUser?.data?.favoriteExhibitorOfferings?.data;
        const favoriteExhibitorOfferingIds: string[] = favoriteExhibitorOfferings?.map((feo) => feo.exhibitorOfferingId)
            || [];

        favoritePredicate = (exhibitorOffering) => favoriteExhibitorOfferingIds
            .some((f) => f === exhibitorOffering.id);
    }

    return favoritePredicate;
}

export function filterOfferings(
    user: States.IAppUserState,
    i18n: Entities.i18nService,
    eventdata: States.IEventDataStore,
    filter: any,
    fieldtemplate: Entities.IFieldTemplate,
    searchable: Entities.IFilterTemplate,
    properties: any
): Entities.IExhibitorOffering[] {
    const predicate = getEntityPredicate<Entities.IExhibitorOffering>(filter, fieldtemplate, searchable);
    const recommendationPredicate = getRecommendationPredicate(user, eventdata, filter);
    const favoritePredicate = getFavoritePredicate(user, filter);
    const res = eventdata.exhibitorofferings.data.filter((o) => predicate(o)
        && recommendationPredicate(o) && favoritePredicate(o));
    let asArray = Array.prototype.slice.call(res) as Entities.IExhibitorOffering[];
    asArray = asArray.sort((a, b) => {
        // if (!a.lastname || !b.lastname) return 0;
        // else return a.lastname.localeCompare(b.lastname);
        const aTitle = a.name && a.name[i18n.currentLanguageCode];
        const bTitle = b.name && b.name[i18n.currentLanguageCode];
        if (aTitle && bTitle) return aTitle.localeCompare(bTitle);

        return 0;
    });
    return SortAndFilterItems(user, i18n, asArray, properties, false, null, fieldtemplate?.workTemplate);
}

export const offeringActions = {
    toggleFavorite: (exhibitorOfferingId: string, i18nHelper: Entities.i18nHelper, elt: HTMLElement) => {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            const data = state.user && state.user.currentUser && state.user.currentUser.data;
            const hasFav = data && data.favoriteExhibitorOfferings && data.favoriteExhibitorOfferings.data
                .filter((e) => e.status === 'Active' && e.exhibitorOfferingId === exhibitorOfferingId)[0];

            if (hasFav) {
                return favoriteExhibitorOfferingActions.removeExhibitorOfferingFromFavorites(
                    exhibitorOfferingId
                )(dispatch, getState);
            }
            return favoriteExhibitorOfferingActions.addExhibitorOfferingToFavorites(exhibitorOfferingId,
                i18nHelper, elt)(dispatch, getState);
        };
    }
};

export const favoriteExhibitorOfferingActions = {
    addExhibitorOfferingToFavorites: (exhibitorOfferingId: string, i18nHelper: Entities.i18nHelper,
        elt: HTMLElement) => (dispatch, getState: () => States.IAppState) => {
        const currentstate = getState();
        if (!currentstate.user.currentUser) {
            return userMessageModule().then((mod) => {
                return mod.loginActions.notifyRequireLogin(elt, i18nHelper)(dispatch, getState);
            });
        }
        if (!currentstate.user.currentUser.detail.id || !currentstate.user.currentUser.detail.isRegistered) {
            return userMessageModule().then((mod) => {
                return mod.messageRegistrationActions.notifyRequireRegistration(elt, i18nHelper)(dispatch, getState);
            });
        }

        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitorofferings', 'add', exhibitorOfferingId)
        );
        return offeringActionQueue().then((mod) => {
            return mod.addExhibitorOfferingToFavorites(dispatch, getState, exhibitorOfferingId)
                .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
        });
    },

    removeExhibitorOfferingFromFavorites: (exhibitorOfferingId: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitorofferings', 'remove', exhibitorOfferingId)
        );
        return offeringActionQueue().then((mod) => {
            return mod.removeExhibitorOfferingFromFavorites(dispatch, getState, exhibitorOfferingId)
                .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
        });
    },

    allowSharingInfoWithExhibitor: (exhibitorOfferingId: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(
                dispatch, getState, 'favoriteexhibitorofferings', 'allowsharinginfo', exhibitorOfferingId
            )
        );
        return offeringActionQueue().then((mod) => {
            return mod.allowSharingInfoExhibitorOffering(dispatch, getState, exhibitorOfferingId)
                .then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
        });
    },
    addExhibitorOfferingsToFavorites: (exhibitorOfferingIds: string[]) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteexhibitorofferingsbatch', 'add')
        );
        return offeringActionQueue().then((mod) => {
            return mod.addExhibitorOfferingsToFavorites(dispatch, getState, exhibitorOfferingIds)
                .then((msgs) => {
                    if (msgs?.length) {
                        msgs.forEach((msg) => actionQueue.enqueue(msg, dispatch, getState));
                    }
                });
        });
    },
};
