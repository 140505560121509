import { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';
import { userMessageModule } from '@@routes/appmodules';
import { actionQueue } from '@@actionsqueue/index';
import * as PersonActionQueue from '@@event/actionsqueue/actionqueue.person';

function eventThemeActionQueue() {
    return import('@@event/actionsqueue/actionqueue.favoriteeventtheme');
}

function tracking() {
    return import("@@services/tracking/actions");
}

export const eventThemeActions = {
    toggleFavorite: (eventThemeId: string, i18nHelper: Entities.i18nHelper, elt: HTMLElement) => {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            const data = state.user && state.user.currentUser && state.user.currentUser.data;
            const hasFav = data && data.favoriteEventThemes && data.favoriteEventThemes.data
                .filter((e) => e.status === 'Active' && e.eventThemeId === eventThemeId)[0];

            if (hasFav) {
                return favoriteEventThemeActions.removeEventThemeFromFavorites(eventThemeId)(dispatch, getState) as Promise<any>;
            }
            return favoriteEventThemeActions.addEventThemeToFavorites(eventThemeId,
                i18nHelper, elt)(dispatch, getState) as Promise<any>;
        };
    }
};

export const favoriteEventThemeActions = {
    addEventThemeToFavorites: (eventThemeId: string, i18nHelper: Entities.i18nHelper,
        elt: HTMLElement) => {
        return (dispatch, getState: () => States.IAppState) => {
            const currentstate = getState();
            if (!currentstate.user.currentUser) {
                return userMessageModule().then((mod) => {
                    return mod.loginActions
                        .notifyRequireLogin(elt, i18nHelper)(dispatch, getState);
                });
            }
            if (!currentstate.user.currentUser.detail.id || !currentstate.user.currentUser.detail.isRegistered) {
                return userMessageModule().then((mod) => {
                    return mod.messageRegistrationActions
                        .notifyRequireRegistration(elt, i18nHelper, null, null)(dispatch, getState);
                });
            }

            tracking().then(
                (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteeventthemes', 'add', eventThemeId)
            );
            return eventThemeActionQueue().then((mod) => {
                return mod.addEventThemeToFavorites(dispatch, getState, eventThemeId)
                    .then((msg) => {
                        actionQueue.enqueue(msg, dispatch, getState);

                        const eventTheme = currentstate.event.data.eventthemes.data.filter((et) => et.id === eventThemeId)[0];
                        if (eventTheme?.showInNetworking) {
                            const prefs = Object.assign({}, currentstate.user?.currentUser?.detail?.preferences);
                            if (!prefs.themes?.filter((t) => t.eventThemeId === eventTheme.id)[0]) {
                                if (!prefs.themes) {
                                    prefs.themes = [];
                                }

                                prefs.themes.push({
                                    eventThemeId: eventThemeId,
                                    propose: [],
                                    search: []
                                });

                                PersonActionQueue.networkingPreferences(dispatch, getState, prefs);
                            }
                        }
                    });
            });
        };
    },

    addEventThemesToFavorites: (eventThemeIds: string[]) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteeventthemesbatch', 'add')
        );
        return eventThemeActionQueue().then((mod) => {
            return mod.addEventThemesToFavorites(dispatch, getState, eventThemeIds)
                .then((msgs) => {
                    if (msgs?.length) {
                        msgs.forEach((msg) => actionQueue.enqueue(msg, dispatch, getState));
                    }
                });
        });
    },

    removeEventThemeFromFavorites: (eventThemeId: string) => (dispatch, getState: () => States.IAppState) => {
        tracking().then(
            (mod) => mod.tracker.trackAction(dispatch, getState, 'favoriteeventthemes', 'remove', eventThemeId)
        );
        return eventThemeActionQueue().then((mod) => {
            return mod.removeEventThemeFromFavorites(dispatch, getState, eventThemeId)
                .then((msg) => {
                    actionQueue.enqueue(msg, dispatch, getState);

                    const currentstate = getState();
                    const eventTheme = currentstate.event.data.eventthemes.data.filter((et) => et.id === eventThemeId)[0];
                    if (eventTheme?.showInNetworking) {
                        const prefs = Object.assign({}, currentstate.user?.currentUser?.detail?.preferences);
                        const prefTheme = prefs.themes?.filter((t) => t.eventThemeId === eventTheme.id)[0];
                        if (prefTheme) {
                            const idx = prefs.themes.indexOf(prefTheme);
                            if (idx > -1) {
                                prefs.themes.splice(idx, 1);
                                PersonActionQueue.networkingPreferences(dispatch, getState, prefs);
                            }
                        }
                    }
                });
        });
    },
};
